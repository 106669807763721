/* eslint-disable max-len, vue/max-len */
/**
 * @file 出海易隐私政策
 * @author dingxin02@baidu.com
 */

export const data = {
    'zh-CN': {
        title: '出海易隐私政策',
        // 版本号
        version: '202203-V1',
        // 范本编号
        templateNo: 'FBOT20220069',
        publishTime: '版本日期：2021-09-30',
        content: `
            <p>欢迎您使用我们的产品和服务！百度公司（本隐私政策中指北京百度网讯科技有限公司，以下亦称“我们”）非常重视您的隐私保护和个人信息保护。本隐私政策适用于您通过任何方式对出海易提供的各项服务的访问和使用，包括出海易网站（<a
                href="https://chuhaiyi.baidu.com" name="首页"
                target="_blank"
            >chuhaiyi.baidu.com</a>）、以及后期可能开发的移动端、APP等在内的各种形态（包括未来技术发展出现的新的服务形态），<a href="https://www.baidu.com/duty/yinsiquan-policy.html" target="_blank">《百度隐私政策总则》</a>将作为补充内容覆盖本政策未涵盖的信息。需要特别说明的是，<strong>本政策不适用于其他第三方向您提供的服务，第三方向您提供的服务适用其向您说明的隐私权政策。</strong></p>
            <p>出海易为您提供出海易产品、资讯等浏览服务，以及<strong><u>针对感兴趣问题留言并留下联系方式（以下亦称“留资”），等候专属顾问与您联系等服务。</u></strong></p>
            <p>本隐私政策旨在帮助您了解我们会收集那些数据、为什么收集这些数据，会利用这些数据做些什么及如何保护这些数据。<strong>请您务必认真阅读本隐私政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分了解并同意后使用出海易的产品或服务。若您使用出海易的相关产品和服务，即表示您充分了解并同意我们在本政策中所述内容。如果您或您的监护人不同意本隐私政策的任何内容，您应该立即停止使用出海易服务。</strong></p>
            <p>本政策将帮助您了解以下内容：</p>
            <p>1、我们如何收集和使用您的个人信息 </p>
            <p>2、我们如何使用Cookie和同类技术 </p>
            <p>3、我们如何共享、转让、公开披露您的个人信息 </p>
            <p>4、我们如何保存及保护您的个人信息 </p>
            <p>5、您的权利 </p>
            <p>6、我们如何处理未成年人的个人信息 </p>
            <p>7、您的个人信息如何在全球范围转移 </p>
            <p>8、本隐私政策如何更新 </p>
            <p>9、如何联系我们</p>
            <p>我们深知个人信息对您的重要性，也深知为您的信息提供有效保护是我们业务健康可持续发展的基石。感谢您对出海易的使用和信任！我们致力于维持您对我们的信任，恪守适用法律和我们对您的承诺，尽全力保证您的个人信息安全和合理使用。同时，我们郑重承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。鉴此，出海易服务提供者（或简称“我们”）制定本《隐私政策》并提醒您：</p>
            <p>我们尽量以更清晰、更容易被您理解的方式展现本隐私政策，从而希望能够真实地传达我们希望向您传达的信息，并希望您在向我们提供某些信息以及允许我们处理并分享某些信息前，能够清晰地了解这些信息收集的目的、可能的用途以及其他方面的内容。</p>
            <h4>一、我们如何收集和使用您的个人信息</h4>
            <p>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。出海易产品应用数据中与您个人无关的信息不属于您的个人信息。</p>
            <p>当您使用以下服务、功能或设置时，我们会以下列方式收集和使用您的个人信息：</p>
            <p><strong>1、您直接提供和我们自动采集的个人信息</strong></p>
            <p>出海易各项业务功能包括为用户提供<strong>多渠道海外广告投放，独立站建站及商品管理，SEO优化，境外海关数据及精准邮箱获取，EDM邮件营销等服务。</strong></p>
            <p>除上述业务功能外，出海易接入了其他第三方服务，包括<u>通过使用三方工具进行SEO相关信息查询服务、海关数据信息查询服务</u>等，<strong>请注意，第三方服务提供方对您个人信息的处理将适用该第三方服务的隐私政策。</strong></p>
            <p><strong>1) 注册信息。</strong></p>
            <p>在您使用出海易提供的服务时，您可以登录经注册的百度商业账号。当注册百度商业账号时，您需向我们提供以下信息：帐号名称、手机号，并创建密码。（注册步骤及注意事项请见<a href="https://u.baidu.com/ucweb/?module=Reguser&controller=reg&action=index&appid=3#/base" target="_blank">https://u.baidu.com/ucweb/?module=Reguser&controller=reg&action=index&appid=3#/base</a>）</p>
            <p><strong>为便于向您提供我们的产品或服务，我们会记录您提供的信息，例如使用出海易进行留言咨询时，您需要主动填写手机号码、联系人姓名、企业名称、企业所在地、所售商品名称等信息，您授权我们收集您填写的信息，并同意我们将您填写的信息告知与我们合作的第三方服务商，以便专属服务商与您联系并提供相应服务；</strong></p>
            <p>当您使用出海易的特定业务功能时，为满足向您提供该产品和服务之目的，除注册信息外，您还需要进一步提供包括但不限于您的个人身份信息及其他为您提供服务所必须信息，如果您不使用特定产品和服务，则无需提供相关信息。如果仅仅需使用浏览功能，您无需登录百度商业账号。</p>
            <p><strong>对于需要通过百度商业账号才能使用的出海易服务，我们可能会根据您提供的上述信息校验您的身份，确保我们是在为您本人提供服务。</strong></p>
            <p><strong>我们的一些产品或服务需要付费才能使用，当您需要使用这些付费产品或服务时，您需要提供您的银行卡信息等，以便我们了解您的支付状态。您可以通过出海易为其他人订购产品或服务，您需要提供该实际订购人的前述个人信息。向我们提供该实际订购人的前述个人信息之前，您需确保您已经取得其授权同意。</strong></p>
            <p><strong>2) 设备信息。</strong></p>
            <p>我们会使用各种技术进行定位，这些技术包括 IP 地址、GPS 以及能够提供相关信息的其他传感器（比如可能会为我们提供附近设备、Wi-Fi 接入点和基站的信息）。<strong>您可以随时联系我们，要求我们停止对您的地理位置信息的收集，之后您可能将无法使用上述基于位置信息提供的相关服务或功能，或者无法达到上述基于位置信息提供的相关服务拟达到的效果，但不会影响您正常使用出海易网站的其他业务功能。</strong></p>
            <p><strong>3) 日志信息。</strong></p>
            <p>与大多数互联网服务一样，当您使用出海易的各项服务时，我们的服务器会自动记录您的日志信息，例如您输入的搜索关键词信息和点击的链接，您的浏览记录，您的订单信息等、您的IP 地址、浏览器的类型和使用的语言、硬件设备信息、操作系统的版本、网络运营商的信息、您访问服务的日期、时间、时长等您在使用我们的产品或服务时提供、形成或留存的信息。</p>
            <p><strong>2、我们间接收集的您的信息</strong></p>
            <p><strong>1) 您使用的第三方产品或服务的信息。</strong></p>
            <p><strong>出海易接入了的第三方服务。当您使用出海易的第三方服务时，可能需要提交您的个人信息。我们将基于您的授权将该服务所必要的信息提供给为您提供服务的第三方，同时基于您的授权向第三方获取必要的相关信息。关于第三方服务具体如何收集、使用您的个人信息，建议您参考第三方服务的相关服务协议及隐私政策。</strong></p>
            <p>2) 为共同向您提供产品/服务或改进产品服务的质量和个性化程度或出于对产品和服务安全性的考量等合理需要，我们将按照法律法规的规定或基于您的授权从百度平台、关联公司、合作伙伴及其他受信任的第三方供应商处接收您的个人信息及其他信息，并依照您与第三方的约定或本隐私政策使用您的个人信息，两者出现冲突以本隐私政策为准。</p>
            <p><strong>3、我们可能如何使用收集到的信息</strong></p>
            <p>1）提供和改进产品和服务</p>
            <p>我们使用收集的信息来提供并改进我们及我们的关联公司、合作伙伴及其他受信任的第三方供应商、服务商及代理商提供的产品和服务，并进行必要的业务运营，例如运营产品或提供服务、评估、维护和改进产品或服务的性能，参与有关我们产品和服务的市场调查活动，开发新的产品或服务、提供客户支持等。</p>
            <p>为改进我们及我们的关联公司、合作伙伴及其他受信任的第三方供应商、服务商及代理商的产品和服务，百度公司也可能会对产品使用情况进行统计和分析。同时，百度公司可能会与公众共享这些统计信息，以展示我们服务的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。</p>
            <p>2）与您沟通</p>
            <p>我们会利用收集的信息（例如您的手机号码、电子邮件地址）直接与您沟通或互动。例如，如果我们检测到可疑活动（例如尝试从不同于平常的位置登录您的百度商业帐号）时，可能会向您发送通知。或者，我们可能与您联系，让您了解百度服务即将发生的变化或改进，邀请您参加新产品的体验等。</p>
            <p>为了让您有更好的体验、改善我们的服务或经您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某些服务所收集的信息用于我们的其他服务。例如，将您在使用我们某项服务时的信息，用于另一项服务中向您展示个性化的内容或广告、用于用户研究分析与统计等服务。</p>
            <p>3）为您提供安全保障</p>
            <p>为提高您使用我们与百度关联方、合作方提供的产品和服务的安全性，我们可能使用您的信息用于身份验证、客户服务、安全防范、诈骗监测、信贷分析等，以预防、发现、调查欺诈、危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您、我们的其他用户、我们或百度关联方、合作方及社会公众的合法权益。</p>
            <p><strong>4、事先征得授权同意的例外</strong></p>
            <p>请注意：在以下情形中，收集、使用个人信息无需事先征得您的授权同意：</p>
            <p>a) 与国家安全、国防安全直接相关的； </p>
            <p>b) 与公共安全、公共卫生、重大公共利益直接相关的； </p>
            <p>c) 与犯罪侦查、起诉、审判和判决执行等直接有关的； </p>
            <p>d) 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； </p>
            <p>e) 所收集的个人信息是您自行向社会公众公开的； </p>
            <p>f) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道； </p>
            <p>g) 根据您的要求向您提供产品或服务所必需的； </p>
            <p>h) 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障； </p>
            <p>i) 为新闻单位开展合法的新闻报道所必需的； </p>
            <p>j) 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的； </p>
            <p>k) 法律法规规定的其他情形。</p>
            <p><strong>5、个人信息的匿名化处理</strong></p>
            <p>在收集到您的个人信息后，我们将通过技术手段及时对数据进行匿名化处理。在不泄露您个人信息的前提下，百度公司有权对匿名化处理后的用户数据库进行挖掘、分析和利用（包括商业性使用），有权对产品/服务使用情况进行统计并与公众/第三方共享脱敏的统计信息。</p>
            <p><strong>6、您授权我们在您使用出海易产品及服务的期间使用您的个人信息，若您注销帐号，我们将根据本隐私政策的规定处理您的个人信息。</strong></p>
            <p><strong>7、我们可能使用您的信息用于安全防范和诈骗监测等，以预防、发现、调查欺诈、危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您、我们或我们的关联公司、合作伙伴及社会公众的合法权益。</strong></p>
            <p><strong>8、当我们要将信息用于本隐私政策未载明的其它用途时，会事先征求您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</strong></p>
            <h4>二、我们如何使用 Cookie 和同类技术</h4>
            <p><strong>Cookie和匿名标识符工具。</strong>Cookie是支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制。当您使用出海易产品或服务时，我们会向您的设备发送一个或多个Cookie或匿名标识符。当您与出海易服务进行交互时，我们允许Cookie或者匿名标识符发送给百度公司服务器。Cookie 通常包含标识符、站点名称以及一些号码和字符。运用Cookie技术，百度公司能够了解您的使用习惯，记住您的偏好，省去您输入重复信息的步骤，为您提供更加周到的个性化服务，或帮您判断您账户的安全性。Cookie还可以帮助我们统计流量信息，分析页面设计和广告的有效性。</p>
            <p>我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，但您可能因为该等修改，无法登录或使用依赖于Cookie的百度公司提供的服务或功能。</p>
            <p>您可以通过更改您的浏览器设置限制百度公司对Cookie的使用。以百度浏览器为例，您可以在百度浏览器右上方的下拉菜单的“浏览器设置”中，通过“隐私设置——清除浏览数据”，选择清除您的Cookie。如需详细了解其他浏览器如何更改浏览器设置，请参考该浏览器的帮助中心或其他相关功能指引页面，例如：</p>
            <p><a href="https://support.google.com/chrome/search?q=cookie" target="_blank">https://support.google.com/chrome/search?q=cookie</a></p>
            <p><a href="https://support.mozilla.org/zh-CN/products/firefox/protect-your-privacy/cookies" target="_blank">https://support.mozilla.org/zh-CN/products/firefox/protect-your-privacy/cookies</a></p>
            <p><a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCookies" target="_blank">http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCookies</a></p>
            <h4>三、我们如何共享、转让、公开披露您的个人信息以及所接入的第三方</h4>
            <p>仅为实现本隐私政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</p>
            <p><strong>（一）共享</strong></p>
            <p>除非经过您本人事先授权同意，我们不会向除百度关联公司以外的第三方共享您的个人信息，但经过处理无法识别特定个人且不能复原的除外。</p>
            <p><strong>1、在下列情况下，经过您的授权同意，我们可能会共享的个人信息：</strong></p>
            <p><strong>（1）第三方产品或服务提供商。</strong>当您使用第三方产品或服务时，我们会根据您的授权将使用第三方产品或服务所必需的信息提供给第三方产品或服务提供商，以便其基于相关信息为您提供产品或服务。关于第三方服务具体如何收集、使用您的个人信息，建议您参考第三方服务的相关服务协议及隐私政策。</p>
            <p><strong>（2）广告、咨询类服务商/广告主。</strong>未经您授权，我们不会将您的个人信息与提供广告、咨询类服务商共享。您授权我们将经处理无法识别您的身份且接收方无法复原的信息，例如经匿名化处理的用户画像，与广告或咨询类服务商或广告主共享，以帮助其在不识别您个人的前提下，提升广告有效触达率，以及分析我们的产品和服务使用情况等。</p>
            <p><strong>（3）金融服务平台或服务提供商。</strong>您授权我们有权根据您对金融服务类合作伙伴的授权，或基于法律法规、法律程序的要求或强制性的政府要求或司法裁定，对您的个人信息进行匿名化的数据统计和分析处理，并将数据处理的结果提供给金融服务平台或提供商，用于判定您的资信状况，筛选出优质用户，降低信贷坏账率。</p>
            <p>2、对我们与之共享个人信息的公司、组织和个人，我们会对其数据安全环境进行调查，与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
            <p><strong>3、您主动与他人共享的信息</strong></p>
            <p>您可以利用出海易的分享功能与微信好友共享，包括但不限于您的信息、商品信息结果等。请您在使用第三方服务或分享功能前充分考虑信息接收人的信誉情况，并建议您查看您利用的社交网络或第三方服务提供商的隐私声明，以了解他们如何处理您的信息，以便审慎决策。</p>
            <p><strong>（二）转让</strong></p>
            <p>我们不会将您的个人信息转让给除关联公司外的任何公司、组织和个人，但以下情况除外：</p>
            <p>1、事先获得您的明确授权或同意；</p>
            <p>2、满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；</p>
            <p>3、如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性，并尽最大可能确保新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</p>
            <p><strong>（三）公开披露</strong></p>
            <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
            <p>1、获得您的明确同意；</p>
            <p>2、基于法律法规、法律程序、诉讼或政府主管部门强制性要求下。</p>
            <p><strong>（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</strong></p>
            <p>在以下情形中，共享、转让、公开披露个人信息无需事先征得个人信息主体的授权同意：</p>
            <p>1、与国家安全、国防安全直接相关的；</p>
            <p>2、与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p>3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
            <p>4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
            <p>5、个人信息主体自行向社会公众公开的个人信息；</p>
            <p>6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
            <h4>四、我们如何保存及保护您的个人信息</h4>
            <p>1、<strong>保存期限。</strong>您在使用出海易产品及服务期间，我们将持续为您保存您的个人信息。 如果您注销帐户或主动删除上述信息，我们将依据网络安全法等法律法规规定保存您的信息。在您注销帐户或主动删除上述信息后，我们不会再对您的个人信息进行商业化使用，但我们可能会对您的个人信息进行匿名化处理后使用。</p>
            <p>2、<strong>保存地域。您的个人信息均储存于中华人民共和国境内，如需跨境传输，我们将会严格按照法律法规的规定执行，并单独征得您的授权同意。</strong></p>
            <p><strong>3、安全措施。</strong></p>
            <p>1) 我们会以“最小化”原则收集、使用、存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。</p>
            <p>2) 我们非常重视信息安全。我们成立了专责团队负责研发和应用多种安全技术和程序等，我们会对安全管理负责人和关键安全岗位的人员进行安全背景审查，我们建立了完善的信息安全管理制度和内部安全事件处置机制等。我们会采取适当的符合业界标准的安全措施和技术手段存储和保护您的个人信息，以防止您的信息丢失、遭到被未经授权的访问、公开披露、使用、毁损、丢失或泄漏。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。</p>
            <p>3) 我们会对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。我们会对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息。若有违反保密协议的行为，会被立即终止与百度公司的合作关系，并会被追究相关法律责任，对接触个人信息人员在离岗时也提出了保密要求。</p>
            <p>4) 我们提醒您注意，互联网并非绝对安全的环境，<strong>当您通过出海易中嵌入的第三方社交软件等与其他用户交互您的地理位置或行踪轨迹信息时，不确定第三方软件对信息的传递是否完全加密，注意确保您个人信息的安全。</strong></p>
            <p>5) 我们也请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。<strong>请您了解，您使用我们的产品和/或服务时所用的系统和通讯网络，有可能在我们控制之外的其他环节而出现安全问题。</strong></p>
            <p>6) <strong>根据我们的安全管理制度，个人信息泄露、毁损或丢失事件被列为最特大安全事件，一经发生将启动公司最高级别的紧急预案，</strong>由安全部、政府关系部、法务部等多个部门组成联合应急响应小组处理。</p>
            <p><strong>4、安全事件通知</strong></p>
            <p>1) 我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。</p>
            <p>2) 个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，组成紧急应急小组，在最短时间内追溯原因并减少损失。</p>
            <p>3) 在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以站内通知、短信通知、电话、邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
            <h4>五、您的权利</h4>
            <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>
            <p>1、<strong>访问权。</strong>原则上您可以通过如下方式访问您的个人信息：</p>
            <p>帐户信息：您可以随时登录出海易网站，访问或编辑您的帐户中的个人资料信息、更改您的密码、添加安全信息、进行账户关联或身份认证等；</p>
            <p>2、<strong>更正权。</strong>当您发现我们处理的关于您的个人信息有错误时，经对您的身份进行验证，且更正不影响信息的客观性和准确性的情况下，您有权对错误或不完整的信息作出更正或更新，您可以自行在出海易网站进行更正，或通过反馈与报错等将您的更正申请提交给我们。</p>
            <p>3、<strong>删除权。</strong>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
            <p>1) 如果我们违反法律法规或与您的约定收集、使用、与他人共享或转让您的个人信息；</p>
            <p>2) 如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。</p>
            <p>当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>
            <p>4、<strong>撤销权。</strong>每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。</p>
            <p>5、<strong>注销权。</strong>您随时可以与我们联系来注销您的百度商业帐号。一旦您注销百度商业账号，将无法使用包括出海易在内的百度公司全线商业产品的服务，因此请您谨慎操作。我们为了保护您或他人的合法权益会结合您对百度公司各产品的使用情况判断是否支持您的注销请求。除法律法规另有规定外，注销帐号之后我们将停止为您提供产品和服务，并根据您的要求删除您的个人信息。</p>
            <p>6、<strong>提前获知产品和服务停止运营权。</strong>出海易愿一直陪伴您，若因特殊原因导致出海易产品和服务被迫停止运营，我们将提前15日在产品或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。</p>
            <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在三十天内做出答复。</p>
            <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</p>
            <p>7、<strong>在以下情形中，按照法律法规要求，我们将无法响应您的更正、删除、注销信息的请求：</strong></p>
            <p>1) 与国家安全、国防安全直接相关的；</p>
            <p>2) 与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p>3) 与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
            <p>4) 我们有充分证据表明您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；</p>
            <p>5) 响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
            <p>6) 涉及商业秘密的。</p>
            <h4>六、我们如何处理未成年人的个人信息</h4>
            <p>百度公司非常重视对未成年人信息的保护。</p>
            <p>我们的产品、网站和服务主要面向成年人。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。</p>
            <p>尽管当地法律和习俗对儿童的定义不同，但我们将不满 14 周岁的任何人均视为儿童。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</p>
            <p>若您是14周岁以下的未成年人，建议您请您的监护人仔细阅读本隐私政策，并在征得您的监护人同意的前提下使用我们的产品和/或服务或向我们提供信息。</p>
            <p>如果任何时候监护人有理由相信我们在未获监护人同意的情况下收集了未成年人的个人信息，请通过<a href="mailto:chuhaiyi@baidu.com">chuhaiyi@baidu.com</a>联系我们，我们会采取措施尽快删除相关数据。</p>
            <h4>七、您的个人信息如何在全球范围转移</h4>
            <p>原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。</p>
            <p>由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。</p>
            <p>此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。</p>
            <h4>八、本隐私政策如何更新</h4>
            <p>我们的隐私政策可能变更。</p>
            <p>未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本隐私政策所做的任何变更。</p>
            <p>对于重大变更，我们会在产品或服务的主要曝光页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您。若您不同意该等变更，请您立即停止使用出海易产品和服务。</p>
            <p>本政策所指的重大变更包括但不限于：</p>
            <p>1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
            <p>2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
            <p>3、个人信息共享、转让或公开披露的主要对象发生变化；</p>
            <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化； </p>
            <p>5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
            <p>6、个人信息安全影响评估报告表明存在高风险时。</p>
            <p>我们还会将本政策的旧版本存档，供您查阅。</p>
            <p>如有本隐私政策未尽事宜，以<a href="https://www.baidu.com/duty/yinsiquan.html" target="_blank">《百度隐私权保护声明》</a>（<a href="https://www.baidu.com/duty/yinsiquan.html" target="_blank">https://www.baidu.com/duty/yinsiquan.html</a>）为准。</p>
            <h4>九、如何联系我们</h4>
            <p>出海易的成长离不开用户的共同努力，我们非常感谢您对出海易数据更新、使用反馈方面的贡献。</p>
            <p>您可以通过<a href="mailto:chuhaiyi@baidu.com">chuhaiyi@baidu.com</a>联系我们，反馈您对出海易的建议以及在使用过程中遇到的问题，以帮助我们优化产品功能及服务，使更多用户更加便捷的使用我们的产品和服务。</p>
            <p>您可以通过个人信息保护问题反馈平台（<a href="http://help.baidu.com/personalinformation" target="_blank">http://help.baidu.com/personalinformation</a>）同我们联系。</p>
            <p>您也可以通过如下联络方式同我们联系：</p>
            <p>
                中国北京市海淀区上地十街10号
                <br>
                北京百度网讯科技有限公司 法务部
                <br>
                邮政编码：100085
            </p>
            <p>为保障我们高效处理您的问题并及时向您反馈，需要您提交身份证明、有效联系方式和书面请求及相关证据，我们会在验证您的身份处理您的请求。</p>
            <p>如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求解决方案：向北京市海淀区人民法院提起诉讼。</p>
            <h4>附：名词解释</h4>
            <p><strong>个人信息</strong>是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。</p>
            <p><strong>个人敏感信息</strong>是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14岁以下（含）儿童的个人信息等。</p>
            <p><strong>设备</strong>是指可用于使用百度产品和/或服务的装置，例如桌面设备、平板电脑或智能手机。</p>
            <p><strong>唯一设备标识符</strong>是指具有唯一性的设备标识符（有时称为通用专属 ID 或 UUID）是指由设备制造商编入到设备中的一串字符，可用于以独有方式标识相应设备（例如国际移动设备身份码IMEI号、网络设备硬件地址MAC）。不同的设备标识符在有效期、用户是否可以重置以及获取方式方面会有所不同。相应设备可能会有多个不同的具有唯一性的设备标识符。唯一设备标识符可用于多种用途，其中包括安全性和欺诈检测、同步服务（如用户的电子邮件收件箱）、记录用户的偏好设置以及提供具有相关性的广告。</p>
            <p><strong>Cookie</strong>是指支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制，通过增加简单、持续的客户端状态来扩展基于Web的客户端/服务器应用。服务器在向客户端返回HTTP对象的同时发送一条状态信息，并由客户端保存。状态信息中说明了该状态下有效的URL范围。此后，客户端发起的该范围内的HTTP请求都将把该状态信息的当前值从客户端返回给服务器，这个状态信息被称为Cookie。</p>
            <p><strong>匿名标示符</strong>是指百度公司基于设备参数，通过机器算法，生成一个标示符例如BAIDUID、CUID，帮助百度识别一个用户，为其提供更好的产品和/或服务与改善用户体验。</p>
            <p><strong>用户画像</strong>是指通过收集、汇聚、分析个人信息，对某特定自然人个人特征，如其职业、经济、健康、教育、个人喜好、信用、行为等方面做出分析或预测，形成其个人特征模型的过程。直接使用特定自然人的个人信息，形成该自然人的特征模型，称为直接用户画像。使用来源于特定自然人以外的个人信息，如其所在群体的数据，形成该自然人的特征模型，称为间接用户画像。</p>
            <p><strong>匿名化</strong>是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。</p>
            <p><strong>百度平台</strong>是指百度公司旗下各专门频道或平台服务（包括百度搜索、百度百科、百度知道、百度贴吧、百度图片、百度糯米、百度手机助手及其他百度系产品<a href="https://www.baidu.com/more/" target="_blank">https://www.baidu.com/more/</a>）等网站、程序、服务、工具及客户端。</p>
            <p><strong>关联公司</strong>是指出海易的经营者北京百度网讯科技有限公司及其他与百度公司存在关联关系的公司的单称或合称。“关联关系”是指对于任何主体（包括个人、公司、合伙企业、组织或其他任何实体）而言，即其直接或间接控制的主体，或直接或间接控制其的主体，或直接或间接与其受同一主体控制的主体。前述“控制”指，通过持有表决权、合约或其他方式，直接或间接地拥有对相关主体的管理和决策作出指示或责成他人作出指示的权力或事实上构成实际控制的其他关系。</p>
            <p><strong>再次感谢您对出海易的信任和使用！</strong></p>
        `,
    },
    'en-US': {
        title: 'Overseadia Privacy Policy',
        publishTime: 'Version Date: 2021-09-30',
        content: `
        <p>
            Welcome to use our products and services! Baidu (referred to in this Privacy Policy as Beijing Baidu Netcom Technology Co., Ltd., hereinafter also referred to as &quot;we&quot;) attaches great importance to your privacy protection and personal information protection. This Privacy Policy applies to your access and use of the various services provided by Chuhaiyi in any way , including the Chuhaiyi website ( chuhaiyi.baidu.com ), and various forms including mobile terminals and APPs that may be developed later (Including new service forms emerging from future technological development), <a href="https://www.baidu.com/duty/yinsiquan-policy.html">&quot;Baidu Privacy Policy General Provisions&quot; </a>will be used as supplementary content to cover information not covered by this policy. It should be noted that <strong>this policy does not apply to the services provided to you by other third parties, and the services provided to you by the third party shall be subject to the privacy policy explained to you by the third party.</strong>
        </p>
        <p>
            Chuhaiyi&nbsp;provides you with browsing services such as Chuhaiyi products, information, etc., as well as leaving a message and contact information for questions of interest (hereinafter also referred to as &quot;reservation&quot;), waiting for exclusive consultants to contact you and for other services
        </p>
        <p>
            This Privacy Policy is designed to help you understand what data we collect, why we collect it, what we do with it and how to protect it. <strong>Please read this Privacy Policy carefully, especially the terms marked in bold/bold underline, you should read it carefully, and use the products or services of Chuhaiyi after confirming your full understanding and agreement. If you use the relevant products and services of Chuhaiyi, it means that you fully understand and agree to the contents described in this policy. If you or your guardian do not agree with any content of this Privacy Policy, you should immediately stop using the Chuhaiyi service.</strong>
        </p>
        <p>
            This policy will help you understand the following:
        </p>
        <p>
            1. How we collect and use your personal information&nbsp;<br/>2. How we use cookies and similar technologies&nbsp;<br/>3. How do we share, transfer and publicly disclose your personal information&nbsp;<br/>4. How do we save and protect your personal information&nbsp;<br/>5. Your rights&nbsp;<br/>6. How do we handle the personal information of minors&nbsp;<br/>7. How your personal information is transferred globally&nbsp;<br/>8. How to update this privacy policy&nbsp;<br/>9. How to contact us
        </p>
        <p>
            We are fully aware of the importance of personal information to you, and also know that effective protection of your information is the cornerstone of the healthy and sustainable development of our business. Thank you for your use and trust in Chuhaiyi! We are committed to maintaining your trust in us, abide by applicable laws and our commitments to you, and do our best to keep your personal information safe and used appropriately. At the same time, we solemnly promise that we will take corresponding security protection measures to protect your personal information in accordance with the mature security standards in the industry. In view of this, our service provider (or &quot;we&quot;) formulates this &quot;Privacy Policy&quot; and reminds you:
        </p>
        <p>
            We try to present this privacy policy in a way that is clearer and easier for you to understand, so as to hope to convey the information we want to convey to you, and hope that you can clearly understand the purpose of collecting this information, possible use and other aspects before providing certain information to us and allowing us to process and share certain information.
        </p>
        <p>
            <strong>1. How we collect and use your personal information</strong>
        </p>
        <p>
            Personal information refers to all kinds of information recorded electronically or in other ways that can identify the identity of a specific natural person or reflect the activities of a specific natural person alone or in combination with other information. Information that is not related to you in the application data of Chuhaiyi products does not belong to your personal information.
        </p>
        <p>
            We collect and use your personal information in the following ways when you use the following services, features or settings:
        </p>
        <p>
            <strong>1. Personal Information You Provide Directly and We Collect Automatically</strong>
        </p>
        <p>
            The various business functions of Chuhaiyi include providing users with multi-channel overseas advertising, stand-alone website/online store building and commodity management, SEO optimization, overseas customs data and accurate mailbox acquisition, EDM and other services .
        </p>
        <p>
            In addition to the above business functions, Chuhaiyi has access to other third-party <strong>services </strong>, including SEO -related information query services, customs data information query services , etc. through the use of third-party tools <strong>. Processing will be governed by the privacy policy of that third party service.</strong>
        </p>
        <p>
            <strong>1)</strong><strong>&nbsp;</strong><strong>registration message. </strong>When you use the services provided by Chuhaiyi , you can log in to your registered Baidu business account. When registering a Baidu business account, you need to provide us with the following information: account name, mobile phone number, and creating a password. (Please refer to https://u.baidu.com/ucweb/?module=Reguser&amp;controller=reg&amp;action=index&amp;appid=3#/base for registration steps and precautions)
        </p>
        <p>
            <strong>In order to provide you with our products or services, we will record the information you provide. For example, when using Chuhaiyi to leave a message for consultation, you need to take the initiative to fill <strong>in the mobile phone number, contact name, company name, company location, and the name of the goods sold, </strong>etc., you authorize us to collect the information you fill in, and agree that we will inform the third-party service providers that you have filled in the information so that the exclusive service providers can contact you and provide corresponding services </strong>;
        </p>
        <p>
            When you use the specific business functions of Chuhaiyi, in order to meet the purpose of providing you with the products and services, in addition to the registration information, you also need to provide further information including but not limited to your personally identifiable information and other necessary services for you information, which you do not need to provide if you do not use specific products and services. If you only need to use the browsing function, you do not need to log in to your Baidu Business Account.
        </p>
        <p>
            <strong>For services that can only be used through a Baidu business account , we may verify your identity based on the above information you provide to ensure that we are providing services for you.</strong>
        </p>
        <p>
            <strong>Some of our products or services require payment to use. When you need to use these paid products or services, you need to provide your bank card information so that we can understand your payment status. You can order products or services for others through Chuhaiyi, and you need to provide the aforementioned personal information of the actual orderer. Before providing us with the aforementioned personal information of the actual orderer, you need to ensure that you have obtained their authorization and consent.</strong>
        </p>
        <p>
            <strong>2)</strong><strong>&nbsp;</strong><strong>Device Information.</strong>
        </p>
        <p>
            We use a variety of technologies for positioning, including IP addresses, GPS , and other sensors that provide relevant information (such as may provide us with information about nearby devices, Wi-Fi access points, and cell towers). <strong>You can contact us at any time and ask us to stop collecting your geographic location information. After that, you may not be able to use the above-mentioned related services or functions based on location information, or you may not be able to achieve the intended effect of the above-mentioned related services based on location information. , but will not affect your normal use of other business functions of Chuhaiyi website.</strong>
        </p>
        <p>
            <strong>4)</strong><strong>&nbsp;</strong><strong>log information. </strong>Like most Internet services, when you use various services of Chuhaiyi, our server will automatically record your log information, such as the search keyword information you enter and the links you click, your browsing records, your orders Information, etc., your IP address, browser type and language used, hardware device information, operating system version, network operator information, the date, time, and duration of your access to services, etc.,the information you provide, form or retain when using our products or services.
        </p>
        <p>
            <strong>2. Information we collect about you indirectly</strong>
        </p>
        <p>
            <strong>1)</strong><strong>&nbsp;</strong><strong>Information about third-party products or services you use.</strong>
        </p>
        <p>
            <strong>Third-party services accessed by Chuhaiyi </strong><strong>. When you use the third-party services of Chuhaiyi, you may need to submit your personal information. Based on your authorization, we will provide the necessary information for the service to the third party who provides you with the service, and at the same time obtain necessary relevant information from the third party based on your authorization. For details on how the third-party service collects and uses your personal information, you are advised to refer to the relevant service agreement and privacy policy of the third-party service.</strong>
        </p>
        <p>
            2) In order to jointly provide you with products/services or improve the quality and degree of personalization of products and services, or for reasonable needs such as consideration of product and service security , we will follow laws and regulations or based on your authorization from Baidu platform. , affiliates, partners and other trusted third-party suppliers to receive your personal information and other information, and use your personal information in accordance with your agreement with the third party or this Privacy Policy, in the event of a conflict between the two This Privacy Policy shall prevail.
        </p>
        <p>
            <strong>3. How we may use the information we collect</strong>
        </p>
        <p>
            1) To provide and improve products and services
        </p>
        <p>
            We use the information collected to provide and improve the products and services provided by us and our affiliates, partners and other trusted third-party suppliers , service providers and agents, and to conduct necessary business operations, such as operating products Or provide services, evaluate, maintain and improve the performance of products or services,
        </p>
        <p>
            participate in market research activities related to our products and services, develop new products or services, provide customer support, etc.
        </p>
        <p>
            In order to improve the products and services of us and our affiliates, partners and other trusted third-party suppliers , service providers and agents, Baidu may also conduct statistics and analysis on product usage. At the same time, Baidu may share these statistical information with the public to show the overall usage trend of our services, but these statistical information will not contain any identifying information about you.
        </p>
        <p>
            2) Communicate with you
        </p>
        <p>
            We will use the information collected (eg your mobile number, email address) to communicate or interact with you directly. For example, we may send you a notification if we detect suspicious activity, such as an attempt to log into your Baidu Business Account from a different location than usual. Alternatively, we may contact you to let you know about upcoming changes or improvements to Baidu&#39;s services, to invite you to experience new products, etc.
        </p>
        <p>
            In order to give you a better experience, improve our services or other purposes with your consent, we may use the information collected through certain services for our other services, subject to relevant laws and regulations. For example, the information when you use one of our services is used to display personalized content or advertisements to you in another service, and for user research analysis and statistics and other services.
        </p>
        <p>
            3) Provide you with security
        </p>
        <p>
            In order to improve the security of your use of the products and services provided by our affiliates and partners with Baidu, we may use your information for identity verification, customer service, security prevention, fraud monitoring, credit analysis, etc., to prevent, detect, investigate acts that are fraudulent, endanger security, illegal or violate our agreements, policies or rules to protect the legitimate rights and interests of you, our other users, our or Baidu affiliates, partners and the general public.
        </p>
        <p>
            <strong>4. Exceptions to prior authorization and consent</strong>
        </p>
        <p>
            Please note: In the following cases, the collection and use of personal information does not require your prior authorization and consent:
        </p>
        <p>
            a) Those directly related to national security and national defense security;&nbsp;<br/>b) Directly related to public safety, public health, and major public interests;&nbsp;<br/>c) Directly related to criminal investigation, prosecution, trial and execution of judgments;&nbsp;
        </p>
        <p>
            d) In order to protect your or other personal life, property and other major legitimate rights and interests, but it is difficult to obtain oneself’s consent;&nbsp;<br/>e) The collected personal information is disclosed to the public by you;&nbsp;<br/>f) Collecting personal information from legally publicly disclosed information, such as legal news reports, government information disclosure and other channels;&nbsp;<br/>g) necessary to provide you with products or services at your request;&nbsp;<br/>h) Necessary to maintain the safe and stable operation of the products or services provided, such as to detect and deal with failures of the products or services;&nbsp;<br/>i ) Necessary for legitimate news reporting by news organizations;&nbsp;<br/>j) When it is necessary for an academic research institution to carry out statistical or academic research based on public interests, and when it provides the results of academic research or description, it de-identifies the personal information contained in the results;&nbsp;<br/>k) Other circumstances stipulated by laws and regulations.
        </p>
        <p>
            <strong>5. Anonymization of personal information</strong>
        </p>
        <p>
            After collecting your personal information, we will anonymize the data in a timely manner through technical means. On the premise of not revealing your personal information, Baidu has the right to mine, analyze and use (including commercial use) the anonymized user database, and have the right to make statistics on the use of products/services and communicate with the public/third party. Three parties share desensitization statistics.
        </p>
        <p>
            <strong>6. You authorize us to use your personal information during your use of Chuhaiyi products and services. If you cancel your account, we will process your personal information in accordance with the provisions of this Privacy Policy.</strong>
        </p>
        <p>
            <strong>7. We may use your information for security prevention and fraud monitoring, etc., to prevent, detect, investigate fraud, endanger security, illegal or violate our agreements, policies or rules to protect you, us or our legitimate rights and interests of affiliated companies, partners and the general public.</strong>
        </p>
        <p>
            <strong>8. When we want to use the information for other purposes not specified in this Privacy Policy, we will ask for your consent in advance. When we want to use the information collected for a specific purpose for other purposes, we will ask for your consent in advance.</strong>
        </p>
        <p>
            <strong>2. How we use cookies and similar technologies</strong>
        </p>
        <p>
            <strong>Cookies and Anonymous Identifier Tools. </strong>A cookie is a mechanism that enables the server (or script) to store and retrieve information on the client.
        </p>
        <p>
            When you use our products or services, we will send one or more cookies or anonymous identifiers to your device. We allow cookies or anonymous identifiers to be sent to Baidu&#39;s servers when you interact with our services. Cookies usually contain identifiers, site names, and some numbers and characters. Using cookie technology, Baidu can understand your usage habits, remember your preferences, save you the steps of entering repeated information, provide you with more thoughtful personalized services, or help you judge the security of your account. Cookies can also help us count traffic information, analyze page design and the effectiveness of advertising.
        </p>
        <p>
            We will not use cookies for any purpose other than those described in this policy. You can manage or delete cookies according to your preferences. See AboutCookies.org for details. You can clear all cookies saved on your computer, and most web browsers have a cookie-blocking feature. However, if you do this, you need to personally change the user settings every time you visit our website, but you may not be able to log in or use the services or functions provided by Baidu that rely on cookies due to such changes.
        </p>
        <p>
            You can restrict Baidu&#39;s use of cookies by changing your browser settings. Taking Baidu Browser as an example, you can choose to clear your cookies through &quot;Privacy Settings - Clear Browsing Data&quot; in &quot;Browser Settings&quot; in the drop-down menu at the top right of Baidu Browser. For more information on how to change browser settings in other browsers, please refer to that browser&#39;s help center or other relevant feature guide pages, such as:
        </p>
        <p>
            <a href="https://support.google.com/chrome/search?q=cookie">https://support.google.com/chrome/search?q=cookie</a>
        </p>
        <p>
            <a href="https://support.mozilla.org/zh-CN/products/firefox/protect-your-privacy/cookies">https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies</a>
        </p>
        <p>
            <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCookies">http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCookies</a>
        </p>
        <p>
            <strong>3. How do we share, transfer and publicly disclose your personal information and the third parties to which it is accessed</strong>
        </p>
        <p>
            Some of our services will be provided by authorized partners only for the purposes stated in this Privacy Policy. We may share some of your personal information with our partners to provide better customer service and user experience. We will only share your personal information for legal, legitimate, necessary, specific and explicit purposes, and only share personal information necessary to provide services. Our partners are not authorized to use the shared personal information for any other purpose.
        </p>
        <p>
            <strong>(1) Sharing</strong>
        </p>
        <p>
            Except with your prior authorization and consent, we will not share your personal information with third parties other than Baidu affiliates, unless the processing cannot identify a specific individual and cannot be recovered.
        </p>
        <p>
            <strong>1. Personal information that we may share with your consent under the following circumstances:</strong>
        </p>
        <p>
            <strong>(1) Third-party product or service providers. </strong>When you use a third-party product or service, we will provide the third-party product or service provider with the information necessary to use the third-party product or service according to your authorization, so that they can provide you with the product or service based on the relevant information. For details on how the third-party service collects and uses your personal information, you are advised to refer to the relevant service agreement and privacy policy of the third-party service.
        </p>
        <p>
            <strong>(2) Advertising and consulting service providers/advertisers. </strong>Without your authorization, we will not share your personal information with service providers providing advertising and consulting services. You authorize us to share information that cannot be processed to identify you and cannot be recovered by the recipient, such as anonymized user portraits, with advertising or consulting service providers or advertisers to help them avoid identifying you personally , improve the effective reach rate of advertisements, and analyze the usage of our products and services.
        </p>
        <p>
            <strong>(3) Financial service platforms or service providers. </strong>You authorize us to have the right to perform anonymized data statistics and analysis processing on your personal information according to your authorization to financial service partners, or based on the requirements of laws and regulations, legal procedures or mandatory government requirements or judicial rulings, and provide the results of data processing to financial service platforms or providers to determine your credit status, screen out high-quality users, and reduce bad credit rates.
        </p>
        <p>
            2. For companies, organizations and individuals with whom we share personal information, we will investigate their data security environment and sign strict confidentiality agreements with them, requiring them to follow our instructions, this Privacy Policy and any other relevant confidentiality and confidentiality and security measures to process personal information.
        </p>
        <p>
            <strong>3. Information you actively share with others</strong>
        </p>
        <p>
            You can use Chuhaiyi&#39;s sharing function to share with your WeChat friends , including but not limited to your information, product information results, etc. Please fully consider the reputation of the information recipient before using third-party services or sharing functions, and recommend that you check the privacy statement of the social network or third-party service provider you use to understand how they handle your information for prudent decision-making.
        </p>
        <p>
            <strong>(2) Transfer</strong>
        </p>
        <p>
            We will not transfer your personal information to any companies, organizations and individuals other than affiliated companies, except in the following cases:
        </p>
        <p>
            1. Obtain your express authorization or consent in advance;&nbsp;<br/>2. To meet the requirements of laws and regulations, legal procedures or mandatory government requirements or judicial rulings;&nbsp;<br/>3. If we or our affiliates are involved in transactions such as mergers, divisions, liquidations, acquisitions or sales of assets or businesses, your personal information may be transferred as part of such transactions, and we will ensure that such information is transferred Confidentiality at the time, and try our best to ensure that new companies and organizations that hold your personal information continue to be bound by this Privacy Policy, otherwise we will require the company and organization to seek your authorization and consent again.
        </p>
        <p>
            <strong>(3) Public disclosure</strong>
        </p>
        <p>
            We will only publicly disclose your personal information in the following circumstances:
        </p>
        <p>
            1. Obtain your express consent;&nbsp;<br/>2. Based on laws and regulations, legal procedures, litigation or mandatory requirements of government authorities.
        </p>
        <p>
            <strong>(IV) Exceptions to prior authorization and consent when sharing, transferring, and publicly disclosing personal information</strong>
        </p>
        <p>
            In the following cases, the sharing, transfer and public disclosure of personal information does not require the prior authorization and consent of the personal information subject:
        </p>
        <p>
            1. Those directly related to national security and national defense security;&nbsp;<br/>2. Those directly related to public safety, public health, and major public interests;&nbsp;<br/>3. Directly related to criminal investigation, prosecution, trial and execution of judgments;&nbsp;<br/>4. In order to protect the life and property of the personal information subject or other individuals, but it is difficult to obtain the consent of the individual;&nbsp;<br/>5. Personal information disclosed by the subject of personal information to the public on his own;&nbsp;<br/>6. Collecting personal information from legally publicly disclosed information, such as legal news reports, government information disclosure and other channels.
        </p>
        <p>
            <strong>4. How do we save and protect your personal information</strong>
        </p>
        <p>
            <strong>1. Shelf life. </strong>During your use of Chuhaiyi products and services, we will continue to save your personal information for you. If you cancel your account or delete the above information voluntarily, we will save your information in accordance with the Cybersecurity Law and other laws and regulations. After you cancel your account or actively delete the above information, we will no longer use your personal information commercially, but we may anonymize your personal information for use.
        </p>
        <p>
            <strong>2. Save the region. Your personal information is stored within the territory of the People&#39;s Republic of China. If you need to transfer across borders, we will strictly follow the provisions of laws and regulations, and obtain your authorization and consent separately.</strong>
        </p>
        <p>
            <strong>3. Safety measures.</strong>
        </p>
        <p>
            1) We will collect, use, store and transmit user information in accordance with the principle of &quot;minimization&quot;, and inform you of the purpose and scope of the use of relevant information through the user agreement and privacy policy.
        </p>
        <p>
            2) We take information security very seriously. We have set up a dedicated team responsible for the development and application of various security technologies and procedures. We will conduct security background checks on the person in charge of security management and personnel in key security positions. We have established a complete information security management system and internal security incident handling mechanism. Wait. We will take appropriate industry-standard security measures and technical means to store and protect your personal information to prevent your information from being lost, accessed without authorization, publicly disclosed, used, damaged, lost or leaked. We will take all reasonably practicable steps to protect your personal information. We use encryption technology to ensure the confidentiality of data; we use trusted protection mechanisms to prevent data from malicious attacks.
        </p>
        <p>
            3) We will conduct data security awareness training and security capability training and assessment for employees to strengthen employees&#39; awareness of the importance of protecting personal information. We will conduct identity authentication and authority control for employees who handle personal information, and sign confidentiality agreements with employees and partners who have access to your personal information to clarify job responsibilities and code of conduct to ensure that only authorized personnel can access personal information. If there is any violation of the non-disclosure agreement, the partnership with Baidu will be terminated immediately, and relevant legal responsibilities will be investigated. Personnel who have access to personal information will also be required to keep confidential when they leave their posts.
        </p>
        <p>
            4) We remind you that the Internet is not an absolutely safe environment. <strong>When you interact with other users through the third-party social software embedded in Chuhaiyi, it is not sure</strong> <strong>whether the transmission of information by third-party software is completely encrypted, pay attention to ensure the security of your personal information.</strong>
        </p>
        <p>
            5) We also ask you to understand that in the Internet industry, due to the limitations and rapid development of technology and various malicious attack methods that may exist, even if we do our best to strengthen security measures, it is impossible to always guarantee 100% information security. <strong>Please understand that the systems and communication networks you use when using our products and/or services may have security problems in other links beyond our control.</strong>
        </p>
        <p>
            <strong>6)</strong><strong>&nbsp;</strong><strong>According to our security management system, personal information leakage, damage or loss is listed as the most serious security incident. Once it occurs, the company&#39;s highest-level emergency plan will be activated </strong>. emergency response team.
        </p>
        <p>
            <strong>4. Security Incident Notification</strong>
        </p>
        <p>
            1) We will formulate contingency plans for network security incidents to deal with security risks such as system vulnerabilities, computer viruses, network attacks, and network intrusions in a timely manner. and report to the relevant competent authorities in accordance with regulations.
        </p>
        <p>
            2) The leakage, damage and loss of personal information is a major company-level security incident. We will be responsible for regularly organizing working group members to conduct security plan drills to prevent such security incidents from happening. In the event of an unfortunate occurrence, we will start the emergency plan according to the highest priority, and form an emergency response team to trace the cause and reduce losses in the shortest time.
        </p>
        <p>
            3) In the event of an unfortunate personal information security incident, we will promptly inform you of the basic situation and possible impact of the security incident in accordance with the requirements of laws and regulations, the measures we have taken or will take, and advice on risks you can prevent and reduce yourself, remedies for you, etc. We will promptly inform you of the relevant information of the event through the contact information you reserved such as on-site notifications, SMS notifications, phone calls, and emails. When it is difficult to inform you one by one, we will make announcements in a reasonable and effective way. At the same time, we will also proactively report the handling of personal information security incidents in accordance with the requirements of regulatory authorities.
        </p>
        <p>
            <strong>5. Your rights</strong>
        </p>
        <p>
            In accordance with relevant Chinese laws, regulations, standards, and common practices in other countries and regions, we guarantee that you exercise the following rights over your personal information:
        </p>
        <p>
            <strong>1. Access. </strong>In principle, you can access your personal information in the following ways:
        </p>
        <p>
            Account information: You can log in to the Chuhaiyi website at any time, access or edit the personal information in your account, change your password, add security information, perform account association or identity authentication, etc.;
        </p>
        <p>
            <strong>2. Right to Correction. When you find that the personal information we process about you is incorrect, you have the right </strong>to correct the incorrect or incomplete information after verifying your identity and the correction does not affect the objectivity and accuracy of the information or update, you can make corrections on Chuhaiyi website by yourself, or submit your correction application to us through feedback and error reporting.
        </p>
        <p>
            <strong>3. Right to erasure. </strong>You may request us to delete personal information in the following circumstances:
        </p>
        <p>
            1) If we collect, use, share or transfer your personal information in violation of laws and regulations or our agreement with you;&nbsp;<br/>2) If we publicly disclose your personal information in violation of laws and regulations or our agreement with you, you have the right to ask us to immediately stop the public disclosure and issue a notice to require the relevant recipient to delete the corresponding information.
        </p>
        <p>
            When you delete information from our service, we may not delete the corresponding information from the backup system immediately, but will delete the information when the backup is updated.
        </p>
        <p>
            <strong>4. Right of withdrawal. </strong>Each business function requires some basic personal information to be completed. For the collection and use of additionally collected personal information, you may give or withdraw your consent at any time. When you withdraw your consent or authorization, we will not be able to continue to provide you with the services corresponding to the withdrawal of your consent or authorization, and will no longer process your corresponding personal information. However, your decision to withdraw your consent or authorization will not affect the previous processing of personal information based on your consent or authorization.
        </p>
        <p>
            <strong>5. Right of cancellation. </strong>You can contact us at any time to cancel your Baidu Business Account . Once you cancel your Baidu commercial account, you will not be able to use the services of Baidu&#39;s full line of commercial products , including Chuhaiyi , so please proceed with caution. In order to protect the legitimate rights and interests of you or others, we will determine whether to support your cancellation request based on your use of Baidu&#39;s products . Unless otherwise provided by laws and regulations, we will stop providing you with products and services after canceling your account , and delete your personal information according to your request.
        </p>
        <p>
            <strong>6. Know in advance the right to discontinue operation of products and services. </strong>Chuhaiyi is willing to accompany you all the time. If Chuhaiyi’s products and services are forced to stop operating due to special reasons, we will send you an email or other suitable contact information on the main page or website of the product or service 15 days in advance. We will notify you through your method, and will stop the collection of your personal information, and will delete or anonymize your personal information held in accordance with the law.
        </p>
        <p>
            For security, you may be required to provide a written request or otherwise prove your identity. We may ask you to verify your identity before processing your request. We will respond within thirty days.
        </p>
        <p>
            For your reasonable requests, we do not charge fees in principle, but for repeated requests that exceed reasonable limits, we will charge a certain cost as appropriate. For those that are unnecessarily repetitive, require excessive technical means (eg, need to develop new systems or fundamentally change existing practices), pose a risk to the legitimate rights and interests of others, or are highly impractical (eg, involve backing up information stored on tapes). requests, we may refuse them.
        </p>
        <p>
            <strong>7. In the following cases, in accordance with the requirements of laws and regulations, we will not be able to respond to your request for correction, deletion, or cancellation of information:</strong>
        </p>
        <p>
            1) Those directly related to national security and national defense security;&nbsp;<br/>2) Those directly related to public safety, public health, and major public interests;&nbsp;<br/>3) Directly related to criminal investigation, prosecution, trial and execution of judgments;&nbsp;<br/>4) We have sufficient evidence to show that you have subjective malice or abuse of rights (such as your request will endanger public safety and the legitimate rights and interests of others, or your request is beyond the scope of general technical means and commercial costs);&nbsp;<br/>5) Responding to the personal information subject&#39;s request will cause serious damage to the legitimate rights and interests of you or other individuals or organizations;&nbsp;<br/>6) Involving commercial secrets.
        </p>
        <p>
            <strong>6. How we handle the personal information of minors</strong>
        </p>
        <p>
            Baidu attaches great importance to the protection of minors&#39; information.
        </p>
        <p>
            Our products, websites and services are primarily aimed at adults. Children may not create their own user accounts without the consent of their parents or guardians. For the collection of children&#39;s personal information with parental consent, we will only use or publicly disclose this information as permitted by law, expressly consented by a parent or guardian, or necessary to protect the child.
        </p>
        <p>
            Although local laws and customs define children differently, we consider anyone under the age of 14 to be a child. If we discover that we have collected personal information from a child without first obtaining verifiable parental consent, we will try to delete the relevant data as soon as possible.
        </p>
        <p>
            If you are a minor under the age of 14, it is recommended that you ask your guardian to read this Privacy Policy carefully, and use our products and/or services or provide us with information with the consent of your guardian.
        </p>
        <p>
            If at any time the guardian has reason to believe that we have collected the personal information of minors without the consent of the guardian, please contact us at chuhaiyi@baidu.com, and we will take measures to delete the relevant data as soon as possible.
        </p>
        <p>
            <strong>7. How your personal information is transferred globally</strong>
        </p>
        <p>
            In principle, the personal information we collect and generate within the territory of the People&#39;s Republic of China will be stored within the territory of the People&#39;s Republic of China.
        </p>
        <p>
            Since we provide products or services through resources and servers all over the world, this means that, after obtaining your authorization and consent, your personal information may be transferred to jurisdictions outside the country/region where you use the products or services, or subject to access from these jurisdictions.
        </p>
        <p>
            Such jurisdictions may have different data protection laws or even no relevant laws. In such cases, we will ensure that your personal information is adequately and equally protected within the territory of the People&#39;s Republic of China. For example, we may request your consent to transfer personal information across borders, or implement security measures such as data de-identification prior to cross-border data transfers.
        </p>
        <p>
            <strong>8. How to update this privacy policy</strong>
        </p>
        <p>
            Our Privacy Policy is subject to change.
        </p>
        <p>
            We will not reduce your rights under this Privacy Policy without your express consent. We will post any changes to this Privacy Policy on this page.
        </p>
        <p>
            <strong>For major changes, we will notify you on the main exposure page of the product or service or on the website, or send you an email or other suitable means to reach you. If you do not agree with such changes, please stop using Chuhaiyi products and services immediately.</strong>
        </p>
        <p>
            Material changes referred to in this policy include, but are not limited to:
        </p>
        <p>
            1. Significant changes to our service model. Such as the purpose of processing personal information, the type of personal information processed, the way of using personal information, etc.;&nbsp;<br/>2. We have undergone major changes in ownership structure, organizational structure, etc. Such as changes in owners caused by business adjustments, bankruptcy mergers and acquisitions, etc.;&nbsp;<br/>3. The main objects of personal information sharing, transfer or public disclosure have changed;&nbsp;<br/>4. Significant changes in your rights to participate in the processing of personal information and the way they are exercised;&nbsp;<br/>5. When there are changes to the responsible department, contact information and complaint channels that we are responsible for handling personal information security;&nbsp;<br/>6. When the personal information security impact assessment report indicates that there is a high risk.
        </p>
        <p>
            We will also archive older versions of this policy for your review.
        </p>
        <p>
            For matters not covered in this Privacy Policy, the Baidu Privacy Protection Statement ( <a href="https://www.baidu.com/duty/yinsiquan.html">https://www.baidu.com/duty/yinsiquan.html</a>) shall prevail.
        </p>
        <p>
            <strong>9. How to contact us</strong>
        </p>
        <p>
            The growth of Chuhaiyi is inseparable from the joint efforts of users. We are very grateful for your contribution to the data update and use feedback of Chuhaiyi.
        </p>
        <p>
            You can contact us at chuhaiyi@baidu.com, and give feedback on your suggestions on Chuhaiyi and problems encountered during use, so as to help us optimize product functions and services, and make it more convenient for more users to use our products and services.
        </p>
        <p>
            You can contact us through the personal information protection issue feedback platform ( <a href="http://help.baidu.com/personalinformation">http://help.baidu.com/personalinformation </a>).
        </p>
        <p>
            You can also contact us through the following contact methods:
        </p>
        <p>
            No. 10, Shangdi 10th Street , Haidian District, Beijing, China&nbsp;<br/>Legal Department of Beijing Baidu Netcom Technology Co., Ltd.&nbsp;<br/>Postal Code: 100085
        </p>
        <p>
            In order to ensure that we handle your questions efficiently and give you feedback in a timely manner, you are required to submit identification, valid contact information, and written requests and relevant evidence. We will process your request after verifying your identity.
        </p>
        <p>
            If you are not satisfied with our response, especially if you think that our personal information processing behavior has harmed your legitimate rights and interests, you can also seek solutions through the following external channels: file a lawsuit with the People&#39;s Court of Haidian District, Beijing.
        </p>
        <p>
            <strong>Attachment: noun explanation</strong>
        </p>
        <p>
            <strong>Personal information </strong>refers to all kinds of information recorded electronically or in other ways that can identify the identity of a specific natural person or reflect the activities of a specific natural person alone or in combination with other information. Personal information includes name, date of birth, ID number, personal biometric information, address, communication contact information, communication records and content, account password, property information, credit information, whereabouts, accommodation information, health and physiological information, transactions information, etc.
        </p>
        <p>
            <strong>Sensitive personal information </strong>refers to personal information that, once leaked, illegally provided or abused, may endanger personal and property safety, easily lead to damage to personal reputation, physical and mental health, or discriminatory treatment. Sensitive personal information includes ID number, personal biometric information, bank account number, communication records and content, property information, credit information, whereabouts, accommodation information, health and physiological information, transaction information, individuals under the age of 14 (inclusive) information, etc.
        </p>
        <p>
            <strong>device </strong>is a device that can be used to use Baidu&#39;s products and/or services, such as a desktop device, tablet, or smartphone.
        </p>
        <p>
            <strong>unique device identifier </strong>is a unique device identifier (sometimes called a Universal Unique ID or UUID), which is a string of characters programmed into a device by the device manufacturer that can be used to uniquely identify the device (e.g. International Mobile Equipment Identity code IMEI number, network device hardware address MAC). Different device identifiers vary in their validity period, whether they can be reset by the user, and how they are obtained. The corresponding device may have several different unique device identifiers. Unique device identifiers can be used for a variety of purposes, including security and fraud detection, synchronization services (such as a user&#39;s email inbox), recording user preferences, and serving relevant advertising.
        </p>
        <p>
            <strong>cookie </strong>refers to a mechanism that supports server-side (or scripting) storage and retrieval of information on the client-side, extending Web-based client/server applications by adding simple, persistent client-side state. The server sends a status message while returning the HTTP object to the client, which is saved by the client. The range of URLs valid in that state is described in the state information. After that, all HTTP requests within this range initiated by the client will return the current value of the status information from the client to the server, and this status information is called Cookie.
        </p>
        <p>
            <strong>Anonymous identifier </strong>means that Baidu Company generates an identifier such as BAIDUID and CUID through machine algorithm based on device parameters to help Baidu identify a user, provide them with better products and/or services and improve user experience.
        </p>
        <p>
            <strong>User portrait </strong>refers to the process of forming a personal characteristic model by analyzing or predicting the personal characteristics of a specific natural person, such as his occupation, economy, health, education, personal preferences, credit, behavior, etc., by collecting, aggregating, and analyzing personal information.. The personal information of a specific natural person is directly used to form the characteristic model of the natural person, which is called direct user portrait. Using personal information other than a specific natural person, such as the data of the group to which he belongs, forms the characteristic model of the natural person, which is called indirect user portrait.
        </p>
        <p>
            <strong>Anonymization </strong>refers to the process in which the subject of personal information cannot be identified through the technical processing of personal information, and the processed information cannot be recovered. The information obtained by anonymizing personal information is not personal information.
        </p>
        <p>
            <strong>Baidu Platform </strong>refers to the specialized channels or platform services of Baidu Company (including Baidu Search, Baidu Encyclopedia, Baidu Know, Baidu Tieba, Baidu Pictures, Baidu Nuomi, Baidu Mobile Assistant and other Baidu products <a href="https://www.baidu.com/more/">https://www.baidu.com /more/ </a>) and other websites, programs, services, tools and clients.
        </p>
        <p>
            <strong>Affiliated company </strong>refers to the singular or collective name of <a>Beijing Baidu Netcom Technology Co., Ltd.</a>, the operator of Chuhaiyi, and other companies that are affiliated with Baidu. &quot;Association&quot; means with respect to any subject (including an individual, company, partnership, organization or any other entity), that is, a subject directly or indirectly controlled by it, or a subject directly or indirectly controlled by it, or directly or indirectly controlled by it A subject controlled by the same subject. The aforementioned &quot;control&quot; refers to directly or indirectly having the power to instruct or instruct others to instruct the management and decision-making of the relevant subject by holding voting rights, contracts or other means, or other relationships that actually constitute actual control.
        </p>
        <p>
            <strong>Thank you again for your trust and use of Chuhaiyi!</strong>
        </p>
        <p>
            <br/>
        </p>
        `,
    },
};
